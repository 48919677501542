import { pages } from "@/services/requests/requests";
import { useQuery } from "react-query";

function useGetCartList() {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["CART"],
    queryFn: pages.getCartList,
  });

  return { data, isLoading, isFetching };
}

export default useGetCartList;
