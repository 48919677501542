import For from "@/components/Tools/For/For";
import { ICategoryMenu } from "@/types/global.types";
import Link from "next/link";
import { useRouter } from "next/router";
import { MutableRefObject, useEffect, useId, useState } from "react";
import { createPortal } from "react-dom";

interface Props {
    clickTarget: MutableRefObject<HTMLElement | null | undefined>;
    render?: unknown;
    data: ICategoryMenu[];
}

interface ICustomData {
    prev: ICustomData | null;
    data: ICategoryMenu[];
    selectAllSlug: string | null
}

function NavMobileModal({ clickTarget, render, data }: Props) {

    const { events } = useRouter()

    const id = useId();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {


        events.on("routeChangeComplete", setIsOpen.bind(null, false))

        return () => events.off("routeChangeComplete", setIsOpen.bind(null, false))

    }, [])



    const handleToggleAction = () => setIsOpen((prev) => !prev);

    useEffect(() => {
        if (clickTarget.current)
            clickTarget.current.onclick = function () {
                handleToggleAction();
            };
    }, [render, clickTarget.current]);


    const [customData, setCustomData] = useState<ICustomData>({ prev: null, data, selectAllSlug: null });


    const handleItemClick = (item: ICategoryMenu) => {
        const children = item.children || [];

        const slug = item.parent_id ? `/${item.slug}?category_id=${item.id}&sort=new` : `/product-banner/${item.id}`

        setCustomData({ prev: customData, data: children, selectAllSlug: slug });
    };

    const handleBackClick = () => {
        if (customData.prev) {
            setCustomData(customData.prev);
        }
    };


    return (
        isOpen &&
        createPortal(
            <div className="overlay">
                <div className="category-mobile-menu">
                    <div className="top-container">
                        <div className="header">
                            <span className="title">Menyu</span>
                            <div className="img-container" onClick={handleToggleAction}>
                                <img
                                    src={require("@/public/assets/img/x.svg").default.src}
                                    alt="close icon"
                                />
                            </div>
                        </div>
                        <div className="body">
                            <div className="dropdown">
                                <div className="menu">
                                    {
                                        customData.prev &&
                                        <>
                                            <button className="menu-item"
                                                onClick={handleBackClick}
                                            >
                                                <span className="icon-button">&lt;</span>
                                                <h2>Geri</h2>
                                                <span className="icon-right" />
                                            </button>
                                            <Link className="select-all" href={customData.selectAllSlug || "/"}>
                                                Hamısını seç
                                            </Link>
                                        </>
                                    }
                                    {
                                        data?.length && <For each={customData.data}>
                                            {(item, index) => {
                                                if (item.children.length) {

                                                    return <button className="menu-item" key={id + index}
                                                        onClick={() => handleItemClick(item)}
                                                    >
                                                        <span className="icon-button" />
                                                        {item.name}
                                                        <span className="icon-right">&gt;</span>
                                                    </button>
                                                }
                                                return <Link href={`/${item.slug}`} className="menu-item" key={id + index}
                                                >
                                                    <span className="icon-button" />
                                                    {item.name}
                                                </Link>

                                            }}
                                        </For>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>,
            document.body
        )
    );
}

export default NavMobileModal;
