import { useRouter } from 'next/router';
import Cookie from '@/utils/cookies.utils';
import { ComponentType } from 'react';
import { DEFAULT_LOCALE } from '@/constant';

type ComponentProps<T> = T extends ComponentType<infer P> ? P : never;
type WithoutLocalePath<T> = Omit<T, 'locale'>;

function AppTranslation<T extends React.ComponentType<any>>(Component: T) {

    return function WrappedComponent(props: WithoutLocalePath<ComponentProps<T>>) {

        const { locale: lang } = useRouter()

        const locale = lang === 'default' ? DEFAULT_LOCALE : lang

        Cookie.set({
            name: "locale_path",
            val: locale as string

        })

        const customProps = { ...props, locale } as ComponentProps<T>;
        return <Component {...customProps} />;
    };
}

export default AppTranslation;
