import { destroyCookie, parseCookies, setCookie } from "nookies";

interface CookiesSetOptions {
  name: string;
  val: string;
  maxAge?: string;
}

class Cookie {
  static set({ name, val, maxAge }: CookiesSetOptions) {
    setCookie(null, name, val, {
      maxAge: maxAge || 30 * 24 * 60 * 60,
      path: "/",
    });
  }

  static get(name: string): string | null | undefined {
    if (parseCookies()[name] === "null") {
      return undefined;
    }

    return parseCookies()[name];
  }

  static delete(name: string) {
    destroyCookie(null, name);
  }
}

export default Cookie;
