import { shared } from "@/services/requests/requests";
import { useQuery } from "react-query";

function useBrands(params?: { category: string | null; textQuery?: string }) {
  const { data, isLoading } = useQuery(
    ["BRANDS", params],
    shared.getBrands.bind(null, params),
    {
      staleTime: Infinity,
    }
  );

  return {
    data,
    isLoading,
  };
}

export default useBrands;
