
interface Props<T> {
  each: readonly T[] | undefined | null;
  children: (item: T, index: number) => React.ReactNode;
  loading?: React.ReactNode;
  isFetching?: boolean
}

function For<T>({ each, children, loading, isFetching }: Props<T>) {
  if (!each) {
    return loading || "Loading...";
  }

  return <>
    {each?.map((item, index) => children.call(null, item, index))}
    {isFetching && loading}
  </>
}

export default For;
