import { shared } from "@/services/requests/requests";
import { useMutation } from "react-query";

function useLogout() {
  const { mutate: mutateLogout, isLoading: isLoadingLogOut } = useMutation(
    shared.getLogout
  );

  return { mutateLogout, isLoadingLogOut };
}

export default useLogout;
