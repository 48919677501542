import { shared } from "@/services/requests/requests";
import { useQuery } from "react-query";

function useHeaderMenu() {
  const { data, isLoading } = useQuery({
    queryKey: "HEADER_MENU",
    queryFn: shared.getMenu,
    staleTime: Infinity,
  });

  return { data, isLoading };
}

export default useHeaderMenu;
